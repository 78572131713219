<template>
    <div>
        <CommunicationCards
            :data="userCommunication"
            :title="'User'"
        ></CommunicationCards>
    </div>
</template>

<script>
import { http } from '@/services'

import CommunicationCards from './Cards/Communication'

export default {
    name: 'CommunicationUser',
    props: [],
    mounted() {
        this.userCommunicationFetch()
    },
    components: {
        CommunicationCards,
    },

    watch: {},

    data() {
        return {
            userCommunication: null,
        }
    },
    computed: {},
    methods: {
        userCommunicationFetch() {
            http.get('communication_user_list')
                .then((response) => {
                    this.userCommunication = response.data
                })
                .catch((error) => {
                    console.log(error)
                })
        },
    },
}
</script>
